import {
    Swiper,
    A11y,
    Autoplay,
    Controller,
    Pagination,
    Navigation,
    Keyboard,
    Manipulation,
    EffectCards,
    EffectCoverflow,
    EffectCreative,
    EffectCube,
    EffectFade,
    EffectFlip,
    Thumb
} from 'c3base/Resources/Public/Lib/swiper/js/swiper.js';

Swiper.use([
    A11y,
    Autoplay,
    Controller,
    Pagination,
    Navigation,
    Keyboard,
    Manipulation,
    EffectCards,
    EffectCoverflow,
    EffectCreative,
    EffectCube,
    EffectFade,
    EffectFlip,
    Thumb
]);

export { Swiper };